import { graphql } from "gatsby";
import * as React from "react";
import Helmet from "react-helmet";
import Layout from "../components/Layout";

interface IHomePageTemplateProps {
  content;
  description;
  title;
}

export const HomePageTemplate = (props: IHomePageTemplateProps) => (
  <Layout deUrl="/de/" enUrl="/en/" showContactButton={true}>
    <Helmet title={props.title}>
      <meta name="description" content={props.description} />
    </Helmet>

    <div dangerouslySetInnerHTML={{ __html: props.content }} />
    {/* <div className="testimonial">
      <div>
        <p>“</p>
        <p>
          The first position on Googles search result page achieves usually most
          clicks. However this is also the most expensive position. Give it a
          try if the second to fourth display position gives you more visitors
          and more customers for less money.
        </p>
      </div>
      <div>
        <img
          src={viktorBrenner}
          style={{ width: 156, height: 50 }}
          alt="Logo"
        />
        <p>Viktor Brenner</p>
        <p>CEO b4payment</p>
      </div>
    </div> */}
  </Layout>
);

interface IHomepageProps {
  data;
}

export default (props: IHomepageProps) => {
  const { markdownRemark: post } = props.data;
  return (
    <HomePageTemplate
      content={post.frontmatter.content}
      description={post.frontmatter.description}
      title={post.frontmatter.title}
    />
  );
};

export const homePageQuery = graphql`
  query HomePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        description
        content
      }
    }
  }
`;
